<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'

// Import Swiper styles
import 'swiper/css'

defineProps({
  slice: {
    type: Object,
    default: () => ({}),
  },
  fields: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  slots: [{ name: 'default' }],
  description: 'X by X grid container',
  fields: {
    sliderOnMobile: { type: 'checkbox', label: 'Slider on Mobile?', default: true },
    divideX: { type: 'checkbox', label: 'Border Right Left On?', default: false },
    divideY: { type: 'checkbox', label: 'Border Top Bottom On?', default: false },
    columns: { type: 'select', label: 'Columns', options: { 1: '1', 2: '2', 3: '3', 4: '4' }, default: 3 },
    gap: { type: 'select', label: 'Gap', options: { 0: '0', 2: '2', 4: '4', 6: '6', 8: '8', 12: '12' }, default: 2 },
  },
  name: { label: 'Grid Container', group: 'Glue' },
  templates: [{ label: 'Grid Container', fields: { columns: { value: '2' }, gap: { value: 4 } } }],
})

const swiper = ref<SwiperType | null>(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
</script>

<template>
  <div class="relative small-container">
    <div
      class="grid-cols-1 lg:grid-cols-3 gap-2"
      :class="[
        {
          'hidden lg:grid': fields.sliderOnMobile.value,
          'divide-x divide-zinc-200': fields.divideX.value,
          'divide-y divide-zinc-200': fields.divideY.value,
          'grid': !fields.sliderOnMobile.value,
        },
      ]"
    >
      <VoixSlice v-for="element in slice.elements" :key="element.id" :slice="element" />
    </div>
    <div v-if="fields.sliderOnMobile.value" class="block lg:hidden">
      <Swiper slides-per-view="auto" :space-between="10" :loop="slice.elements.length > 1" @swiper="onSwiper">
        <SwiperSlide v-for="(element, key) in slice.elements" :key="key" class="max-w-full">
          <VoixSlice :slice="element" :slice-index="key" />
        </SwiperSlide>
      </Swiper>

      <div
        class="relative z-30 mt-12 mb-4 lg:inset-0 pointer-events-none flex lg:justify-between items-center"
      >
        <button
          class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 left-0 ml-10 -mt-8 justify-center items-center"
          @click="prev"
        >
          <div class="slider-button text-blue-300 bg-orange-50">
            <IconsArrow class="w-5 pointer-events-none" />
          </div>
        </button>
        <button
          class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 right-0 ml-4 -mt-8 justify-center items-center"
          @click="next"
        >
          <div class="slider-button text-blue-300 bg-orange-50">
            <IconsArrow class="w-5 pointer-events-none transform rotate-180" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
  display: flex;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
</style>
